

body 
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #F5FAF9;
}
a{
  text-decoration: none;
}


li{
  list-style-type: none
}

@tailwind base;
@tailwind components;
@tailwind utilities;



.standardBtn{
  @apply h-[48px] lg:h-[70px] w-[236px] lg:w-[272px] bg-[#008000] text-white rounded-[15px] mt-5 text-[22px] lg:text-[25px] font-[700]
}

.standardBtnLong{
  @apply h-[48px] lg:h-[70px] w-[357px] lg:w-[389px] bg-[#008000] text-white rounded-[15px] mt-5 text-[22px] lg:text-[25px] font-[700]
}